'use strict';

var $ = require('jquery'),
    frontendKit = require('../frontend/utils');

function checkIfMobile() {
    var isMobile = frontendKit.isMobile;
    if(isMobile) {
        $('.main-menu').addClass('isMobile');
    } else {
        $('.main-menu').removeClass('isMobile');
    }
}

module.exports = {
    bindEvHandlers: function () {
        $(function () {
            checkIfMobile();
        });

        $(window).on('resize', function() {
            checkIfMobile();
        });

        var debounceBackBtn = frontendKit.debounce(
            function(ev) {
                ev.preventDefault();
                $(ev.target).parents('.show').eq(0).removeClass('show');
                if($(ev.target).parents('.show').length < 1) $('.navbar').addClass('hasOverflow')
            }, 500
        );

        $('body').on('click', '.isMobile .dropdown-menu-back', debounceBackBtn);

        $('body').on('click', '.nav-link', function() {
            $('.navbar').removeClass('hasOverflow');
        });
    },

    back: function () {
        $('.dropdown-menu-back').on(function() {
            $('.pull-left').trigger('click');
        });
    }
};
